<template>
    <div class="index">
      <img src="@/assets/logo.png"/>
      <h1>404</h1>
      <h2>Страница не найдена</h2>
      <button @click="goBack">Вернуться назад</button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFoundPage',
    methods: {
      goBack() {
        this.$router.go(-1);
    }
  }
  };
  </script>
  
  <style scoped>
  .index {
    padding: 20px;
  }
  </style>