<template>
    <div class="index">
      <img src="@/assets/logo.png"/>
      <h1>500</h1>
      <h2>Ошибка</h2>
      <button @click="gomain">Вернуться на главную</button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ErrorPage',
    methods: {
      gomain() {
        this.$router.push('/');
    }
  }
  };
  </script>
  
  <style scoped>
  .index {
    padding: 20px;
  }
  </style>