<template>
    <div class="index">
      <h1>Welcome to the Index Page</h1>
      <router-link to="/auth">Go to Auth Page</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'IndexPage'
  };
  </script>
  
  <style scoped>
  .index {
    padding: 20px;
  }
  </style>