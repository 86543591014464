<template>
  <div class="auth">
    <img class="logo" src="@/assets/logo.png"/>
    <h1>VigiLux</h1>
    <h2>Введите ваши учетные данные</h2>
    <form @submit.prevent="handleSubmit">
      <div>
        <label for="username">Имя пользователя</label>
        <input type="text" id="username" v-model="username" required>
      </div>

      <div>
        <label for="password">Пароль</label>
        <input type="password" id="password" v-model="password" required>
      </div>

      <button type="submit">Вход</button>
    </form>

  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'AuthPage',
  setup() {
    const username = ref('');
    const password = ref('');

    const handleSubmit = () => {
      // Здесь можно добавить логику проверки и отправки данных на сервер
      console.log('Username:', username.value);
      console.log('Password:', password.value);

      // Сброс данных после отправки
      username.value = '';
      password.value = '';
    };

    return {
      username,
      password,
      handleSubmit
    };
  }
};
</script>

<style scoped>

.auth {
  padding: 20px;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center; 
 
}


form div {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  padding: 10px 15px;
  cursor: pointer;
}

button:hover {
  background-color: #ddd;
}
</style>