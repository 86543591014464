import { createRouter, createWebHistory } from 'vue-router';
import IndexPage from '../views/Index.vue';
import AuthPage from '../views/Auth.vue';
import ErrorPage from '../views/5xx.vue';
import NotFoundPage from '../views/404.vue';

const routes = [
  {
    path: '/',
    name: 'Index',
    component: IndexPage
  },
  {
    path: '/auth',
    name: 'Auth',
    component: AuthPage
  },
  {
    path: '/50x',
    name: 'Erro 5xx',
    component: ErrorPage
  },
  {
    path: '/404',
    name: 'Erro 404',
    component: NotFoundPage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;